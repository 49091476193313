.app {
  .nav-logo {
    height: 121px;
  }

  .wnavdesk {
    background-color: black;
  }
  .showcase {
    background-image: url("../assets/frontimg/showcase.jpg");
    background-position: 50% 50%;
    background-size: cover;
    height: 40vh;
  }
  .mint {
    height: 30.5vh;

    .mint-box {
      background-color: #fef7f7;
      border: 3px solid red;
      height: 250px;
      border-radius: 16px;
      width: 550px;
    }
  }

  @import url('https://fonts.googleapis.com/css?family=Bangers');

  .clock {
    position: relative;
    margin: a;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(270deg, #ffffff, #ffffff);
    height: 90px;
    //width: 360px;
    color: #ff0000;
    //text-shadow: 1px 1px 7px;
    border-radius: 15px;
    border: 0px solid silver;
    //box-shadow: 0 0 5px 25px;
    background-color: #000;
  }
  
  .clock section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  .clock section p {
    font-size: 4rem;
    font-family:Bangers;
  }
  .clock section small {
    color: silver;
    text-shadow: none;
  }
  .endawki{
    font-size: calc(16px + 2vw);
    font-family:Bangers;
    position: relative;
    margin: a;
    display: flex;
    justify-content: center;
    align-items: center;
  }


}

.custom-button{
  cursor: pointer!important;
}

.about {
  background-color: rgb(247, 54, 20);
  .con {
    padding: 2rem;
  }
  .about-text {
    font-size: 1.5rem;
    color: #fff;
  }
  // .about-img {
  //   // height: 450px;
  // }
}

.utility {
  margin: 3rem 0;
  .title {
    font-size: 3rem;
    letter-spacing: 3px;
  }
  .utility-text {
    font-size: 1.5rem;
  }
}

.roadmap {
  padding: 1rem 0;
  background-color: rgb(247, 54, 20);
  .mb-4{
    background-color: #fff;
  }
  .title {
    font-size: 3rem;
    letter-spacing: 3px;
    color: #fff;
    text-align: center;
  }
  .cmp1{
    background-image: url("../assets/images/vvariant.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .cmp2{
    background-image: url("../assets/images/comingBG.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .cmp3{
    background-image: url("../assets/images/comingB2G.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .rbox {
    background-color: #fff;
    padding: 2rem 1rem;
    border-radius: 20px;
    height: 400px;
  }
  .lonfgbox {
    background-color: #f73614;
    color: #fff;
    padding: 1rem;
    border-radius: 20px;
    height: auto;
    font-size: 1.6rem;
  }
  .utility-text {
    font-size: 1.5rem;
  }
  .roadmap-img {
    height: 100px;
    margin-top: 1rem;
  }
}

.footer {
  padding: 1rem 0;

  .centralxd{
    text-align:
     center;
  }

  .title {
    font-size: 3rem;
    letter-spacing: 3px;
    color: #fff;
    text-align: center;
  }

  .footer-text {
    font-size: 1.5rem;
  }
}
.footer-img {
  height: 210px;
  margin-top: 1rem;
  // position: absolute;
}

.introx {
  padding: 1rem;
  width: 100%;
  text-align:
     center;

  .title {
    font-size: 3rem;
    letter-spacing: 3px;
    color: #fff;
    text-align: center;
  }

  .introx-text {
    font-size: 1.2rem;
    text-align:
     center;
     margin: auto;
     width: 80%;
  }

  .tinwuv{
    font-size: .8rem;
  }
}
.introx-img {
  height: 210px;
  margin-top: 1rem;
  // position: absolute;
}

.slidecontainer {
  width: 50%;
}

.slider {
  -webkit-appearance: none;
  width: 300px;
  height: 15px;
  border-radius: 5px;
  background: #47dba5;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .rbox {
    margin-top: 2rem;
  }
  .footer-img {
    height: 70px;
  }
  .fs-icon {
    height: 40px !important;
  }
  .res-col {
    margin-top: 0.5rem;
  }
  .nav-logo {
    height: 100px !important;
  }
  .c-res {
    margin-bottom: 1rem;
  }
  .mint {
    .title {
      font-size: 1.5rem;
    }
    .subtitle {
      font-size: 2rem;
    }
    .mint-box {
      margin: 0 1rem;
    }
  }
  .rmt {
    margin-top: 2.5rem !important;
  }
}

//------------------------------------------------

